<template>
    <section class="blockElement space bg-white">
        <div class="container">
            <div class="row">
                <div class="co-12 text-center">
                    <h1>{{$t('deleteAccount.text1')}}</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white pb-0">
        <div class="container bodyBG radiusSpace">
            <div class="row align-items-center">
                <div class="co-12 col-md-6 numaricPlan">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="gradientSec mb-0 me-4 pe-md-2">
                            <span class="counterd">1</span>
                        </div>
                        <h2 class="semibold mb-0">{{$t('deleteAccount.text2')}}</h2>
                    </div>
                </div>
                <div class="co-12 col-md-6">
                    <div class="deleteAccout_Img">
                        <v-lazy-image class="mx-auto d-block position-relative" width="386" height="495" src="/assets/images/delete_account/del_1.jpg" alt="Log in" :title="$t('deleteAccount.text2')" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white pb-0">
        <div class="container bodyBG radiusSpace">
            <div class="row align-items-center flex-row-reverse">
                <div class="co-12 col-md-6 numaricPlan">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="gradientSec mb-0 me-4 pe-md-2">
                            <span class="counterd">2</span>
                        </div>
                        <h2 class="semibold mb-0">{{$t('deleteAccount.text3')}}</h2>
                    </div>
                </div>
                <div class="co-12 col-md-6">
                    <div class="deleteAccout_Img">
                        <v-lazy-image class="mx-auto d-block position-relative" width="386" height="495" src="/assets/images/delete_account/del_2.jpg" alt="Log in" :title="$t('deleteAccount.text3')" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white pb-0">
        <div class="container bodyBG radiusSpace">
            <div class="row align-items-center">
                <div class="co-12 col-md-6 numaricPlan">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="gradientSec mb-0 me-4 pe-md-2">
                            <span class="counterd">3</span>
                        </div>
                        <h2 class="semibold mb-0">{{$t('deleteAccount.text4')}}</h2>
                    </div>
                </div>
                <div class="co-12 col-md-6">
                    <div class="deleteAccout_Img">
                        <v-lazy-image class="mx-auto d-block position-relative" width="386" height="495" src="/assets/images/delete_account/del_3.jpg" alt="Log in" :title="$t('deleteAccount.text4')" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white pb-0">
        <div class="container bodyBG radiusSpace">
            <div class="row align-items-center flex-row-reverse">
                <div class="co-12 col-md-6 numaricPlan">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="gradientSec mb-0 me-4 pe-md-2">
                            <span class="counterd">4</span>
                        </div>
                        <h2 class="semibold mb-0">{{$t('deleteAccount.text5')}}</h2>
                    </div>
                </div>
                <div class="co-12 col-md-6">
                    <div class="deleteAccout_Img">
                        <v-lazy-image class="mx-auto d-block position-relative" width="386" height="495" src="/assets/images/delete_account/del_4.jpg" alt="Log in" :title="$t('deleteAccount.text5')" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white">
        <div class="container bodyBG radiusSpace">
            <div class="row align-items-center">
                <div class="co-12 col-md-6 numaricPlan">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="gradientSec mb-0 me-4 pe-md-2">
                            <span class="counterd">5</span>
                        </div>
                        <h2 class="semibold mb-0">{{$t('deleteAccount.text6')}}</h2>
                    </div>
                </div>
                <div class="co-12 col-md-6">
                    <div class="deleteAccout_Img">
                        <v-lazy-image class="mx-auto d-block position-relative" width="386" height="495" src="/assets/images/delete_account/del_5.jpg" alt="Log in" :title="$t('deleteAccount.text6')" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white holdDashed py-md-4">
        <div class="container">
            <div class="row align-items-center justify-centent-center">
                <div class="co-12 numaricPlan">
                    <div class="gradientSec mb-0 me-4 pe-md-2">
                        <span class="counterd">{{$t('deleteAccount.text7')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement space bg-white">
        <div class="container bodyBG radiusSpace border">
            <div class="row justify-content-center">
                <div class="co-12 mb-3">
                    <div class="bg-secondary text-center br-10 p-3">
                        <p class="mb-0 text-white">{{$t('deleteAccount.text8')}} <b>{{$t('deleteAccount.text9')}}</b> {{$t('deleteAccount.text10')}} ‘<b>{{$t('deleteAccount.text11')}} </b>’.</p>
                    </div>
                </div>
                <div class="co-12 col-md-8 text-center">
                    <p class="mb-0">{{$t('deleteAccount.text12')}}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data() {
            return {
               
            };
        },
    };
</script>
